import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ThreeColumnLayout from './components/ThreeColumnLayout';
import TweetTestPage from './components/TweetTestPage';
import Header from './components/header';  // Import the Header component
import { Analytics } from '@vercel/analytics/react';



function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<ThreeColumnLayout />} />
        <Route path="/tweet-test" element={<TweetTestPage />} />
      </Routes>
      <Analytics /> 
    </Router>
  );
}

export default App;
