import React, { useEffect, useState } from 'react';
import { fetchTweets } from '../airtableService';
import { format } from 'date-fns'; // Install date-fns to format dates

const TweetTestPage = () => {
  const [tweetsByDate, setTweetsByDate] = useState({});

  useEffect(() => {
    const loadTweets = async () => {
      const tweetData = await fetchTweets();

      // Group tweets by date
      const groupedTweets = tweetData.reduce((acc, tweet) => {
        const tweetDate = format(new Date(tweet.created), 'MMMM do, yyyy'); // e.g., 'September 4th, 2024'
        if (!acc[tweetDate]) {
          acc[tweetDate] = [];
        }
        acc[tweetDate].push(tweet);
        return acc;
      }, {});

      setTweetsByDate(groupedTweets);
    };

    loadTweets();
  }, []);

  useEffect(() => {
    // Load the Twitter embedding script
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [tweetsByDate]);

  const distributeTweetsIntoColumns = (tweets) => {
    const columns = [[], [], []]; // Left, Center, Right columns
    tweets.forEach((tweet, index) => {
      columns[index % 3].push(tweet);  // Round robin distribution
    });
    return columns;
  };

  return (
    <div className="container mx-auto p-4">
      {Object.keys(tweetsByDate).map((date, dateIndex) => (
        <div key={dateIndex} className="mb-8">
          {/* Date Heading */}
          <h2 className="text-xl font-bold text-left mb-4">{date}</h2>

          {/* Round-robin Tweets for this date */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            {distributeTweetsIntoColumns(tweetsByDate[date]).map((column, colIndex) => (
              <div key={colIndex} className="space-y-0">
                {column.map((tweet, tweetIndex) => (
                  <div key={tweetIndex} className="bg-white p-1 rounded-lg ">
                    <blockquote className="twitter-tweet">
                      <a href={tweet.tweetURL}></a>
                    </blockquote>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TweetTestPage;
