import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../media/highlandLogo.png';

const Header = () => {
  return (
    <header className=" p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Left side - Logo */}
        <div className="text-black text-xl font-bold">
          <Link to="/">
          <img src={logo} alt="Logo" className="h-20" />  {/* Adjust the height as needed */}

          </Link>
        </div>

        {/* Right side - Navigation */}
        <nav className="space-x-4">
          <Link to="/" className="text-black hover:text-gray-400">Home</Link>
          <Link to="/tweet-test" className="text-black hover:text-gray-400">Tweets</Link>
          <Link to="/youtube" className="text-black hover:text-gray-400">YouTube</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
