import axios from 'axios';

const AIRTABLE_ACCESS_TOKEN = process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN;
const BASE_ID = process.env.REACT_APP_BASE_ID;

// Define base URLs for each table
const titlesBaseURL = `https://api.airtable.com/v0/${BASE_ID}/${process.env.REACT_APP_TABLE_NAME}`;
const tweetsBaseURL = `https://api.airtable.com/v0/${BASE_ID}/tweetData`;
const youtubeBaseURL = `https://api.airtable.com/v0/${BASE_ID}/youtubeData`;

// Create axios instances for each table
const airtableTitles = axios.create({
  baseURL: titlesBaseURL,
  headers: {
    Authorization: `Bearer ${AIRTABLE_ACCESS_TOKEN}`,
  },
});

const airtableTweets = axios.create({
  baseURL: tweetsBaseURL,
  headers: {
    Authorization: `Bearer ${AIRTABLE_ACCESS_TOKEN}`,
  },
});

const airtableYouTube = axios.create({
  baseURL: youtubeBaseURL,
  headers: {
    Authorization: `Bearer ${AIRTABLE_ACCESS_TOKEN}`,
  },
});

// Fetch titles and links
export const fetchTitlesAndLinks = async () => {
    try {
      const response = await airtableTitles.get();
      const records = response.data.records;
  
      // Sort by the 'Created' field in descending order (newest first)
      const sortedRecords = records.sort((a, b) => new Date(b.fields.Created) - new Date(a.fields.Created));
  
      // Find the main story
      const mainStory = sortedRecords.find(record => record.fields.MainStory === 'Yes');
  
      // Filter out the main story from the other stories
      const otherStories = sortedRecords.filter(record => record.fields.MainStory !== 'Yes');
  
      return {
        mainStory: mainStory ? {
          id: mainStory.id,
          title: mainStory.fields.Title,
          link: mainStory.fields.Link,
          imageUrl: mainStory.fields['Image URL'],
          created: mainStory.fields.Created,
        } : null,
        stories: otherStories.map(record => ({
          id: record.id,
          title: record.fields.Title,
          link: record.fields.Link,
          imageUrl: record.fields['Image URL'],
          created: record.fields.Created,
        }))
      };
    } catch (error) {
      console.error('Error fetching data from Airtable:', error);
      return { mainStory: null, stories: [] };
    }
  };
  

// Fetch tweets
export const fetchTweets = async () => {
    try {
      const response = await airtableTweets.get(); // Fetch from the correct table name
      const records = response.data.records;
  
      // Sort tweets by 'Created' field in descending order (newest first)
      const sortedTweets = records.sort((a, b) => new Date(b.fields.Created) - new Date(a.fields.Created));
  
      return sortedTweets.map((record) => ({
        id: record.id,
        tweetURL: record.fields.tweetURL.replace('x.com', 'twitter.com'),  // Swap x.com with twitter.com
        created: record.fields.Created,
      }));
    } catch (error) {
      console.error('Error fetching tweets from Airtable:', error);
      return [];
    }
  };

// Fetch YouTube videos
export const fetchYouTubeVideos = async () => {
  try {
    const response = await airtableYouTube.get();
    return response.data.records.map((record) => ({
      id: record.id,
      title: record.title,
      youtubeURL: record.fields.youtubeURL,
    }));
  } catch (error) {
    console.error('Error fetching YouTube videos from Airtable:', error);
    return [];
  }
};
