import React, { useEffect, useState } from 'react';
import { fetchTitlesAndLinks, fetchYouTubeVideos } from '../airtableService';

const ThreeColumnLayout = () => {
  const [mainStory, setMainStory] = useState(null);
  const [stories, setStories] = useState([]);
  const [youtubeVideos, setYouTubeVideos] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const storiesData = await fetchTitlesAndLinks();
      const youtubeVideosData = await fetchYouTubeVideos();

      setMainStory(storiesData.mainStory);
      setStories(storiesData.stories);
      setYouTubeVideos(youtubeVideosData);
    };

    loadData();
  }, []);

  // Distribute articles into 3 columns with 4 rows between video rows
  const distributeContent = (stories, youtubeVideos) => {
    const content = [];
    let videoIndex = 0;

    // Loop through the stories and insert a row of YouTube videos after every 12 stories (4 rows of 3 columns)
    for (let i = 0; i < stories.length; i += 12) {
      // Slice the next 12 stories (4 rows)
      const articlesChunk = stories.slice(i, i + 12);
      
      // Group articles into columns (3 columns layout)
      const columns = [[], [], []];
      articlesChunk.forEach((story, index) => {
        columns[index % 3].push(story); // Add stories into 3 columns
      });
      
      // Add the articles into the content array
      content.push({
        type: 'articles',
        columns: columns,
      });

      // After every 12 articles, insert a row of YouTube videos (3 videos per row)
      if (videoIndex < youtubeVideos.length) {
        const youtubeChunk = youtubeVideos.slice(videoIndex, videoIndex + 3);
        videoIndex += 3;

        content.push({
          type: 'youtube',
          videos: youtubeChunk,
        });
      }
    }

    return content;
  };

  const content = distributeContent(stories, youtubeVideos);

  return (
    <div className="container mx-auto p-4">
      {/* Main Story Section */}
      {mainStory && (
        <div className="text-center mb-8">
          <img
            src={mainStory.imageUrl}
            alt={mainStory.title}
            className="mx-auto mb-4"
          />
          <a href={mainStory.link} target="_blank" rel="noopener noreferrer" className="text-3xl font-bold tracking-wider uppercase underline">
            {mainStory.title}
          </a>
        </div>
      )}

      {/* Loop through content and render articles or YouTube videos */}
      {content.map((section, index) => (
        <div key={index} className="mb-12">
          {section.type === 'articles' ? (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {section.columns.map((column, colIndex) => (
                <div key={colIndex} className="space-y-4">
                  {column.map((story, storyIndex) => (
                    <a
                      key={story.id}
                      href={story.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block text-black underline"
                    >
                      {story.title}
                    </a>
                  ))}
                </div>
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {section.videos.map((video, videoIndex) => (
                <iframe
                  key={videoIndex}
                  width="100%"
                  height="215"
                  src={video.youtubeURL}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ThreeColumnLayout;
